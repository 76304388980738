import React from "react";
import { Button, Typography, Container } from "@material-ui/core";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";

const AlreadyRegistered = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.bodyStyle}>
      <Container maxWidth="md">
        <Typography
          className={classes.typographyStyle}
          variant="h5"
          align="center"
          gutterBottom
        >
          Denna meljadressen är redan registrerad till mässan Sign&Print
          Scandinavia 2025!
        </Typography>
        <Typography
          className={classes.typographyStyle}
          variant="body1"
          align="center"
          gutterBottom
        >
          Notera att du inte kan registrera flera besökare på samma meljadress.
        </Typography>
        <Typography
          className={classes.typographyStyle}
          align="center"
          gutterBottom
        >
          <Button
            onClick={() => {
              history.push("/se/register");
            }}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Försök igen
          </Button>
          <Button
            href="https://www.signochprint.se/"
            variant="outlined"
            color="primary"
          >
            Till mässan
          </Button>
        </Typography>
      </Container>
    </div>
  );
};

export default AlreadyRegistered;
