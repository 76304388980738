import React from "react";
import { Button, Typography, Container } from "@material-ui/core";
import useStyles from "./styles";
//import { Helmet } from "react-helmet";

const ExhibitorThankYou = () => {
  const classes = useStyles();
  return (
    <div className="body">
      <Container maxWidth="md">
        <div className={classes.bodyStyle}>
          <Typography
            className={classes.typographyStyle}
            variant="h4"
            align="center"
            gutterBottom
          >
            Thank you for registering as exhibitors at Sign&Print Scandinavia
            2025!
          </Typography>
          <Typography
            className={classes.typographyStyle}
            variant="body1"
            align="center"
          >
            We will email a ticket to each registered exhibitor.
          </Typography>
          <Typography
            className={classes.typographyStyle}
            variant="body1"
            align="center"
            gutterBottom
          >
            If an exhibitor's email was already registered, we will send another
            email as a reminder.
          </Typography>
          <Typography
            className={classes.typographyStyle}
            align="center"
            gutterBottom
          >
            <Button
              href="https://www.signochprint.se/"
              variant="contained"
              color="primary"
            >
              Go back to the exhibition
            </Button>
          </Typography>
        </div>
      </Container>
      {/* <Helmet>
        <script src="../tracktag.js" type="text/javascript" />
      </Helmet> */}
    </div>
  );
};

export default ExhibitorThankYou;
