import "./App.css";
import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import RegistrationForm from "./components/registrationForm";
import NotFound from "./components/notFound.jsx";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./components/theme";
import Header from "./components/common/header";
import ThankYou from "./components/thankyou";
import Footer from "./components/common/footer";
import AlreadyRegistered from "./components/alreadyRegistered";
import ErrorPage from "./components/errorPage";
import DkRegistrationForm from "./components/dk/dkRegistrationForm";
import ChooseExhibition from "./components/chooseExhibition";
import ExhibitorForm from "./components/exhibitorForm";
import DkExhibitorForm from "./components/dk/dkExhibitorForm";
import BanPage from "./components/banPage";
import DkAlreadyRegistered from "./components/dk/dkAlreadyRegistered";
import DkBanPage from "./components/dk/dkBanPage";
import DkThankYou from "./components/dk/dkThankyou";
import ExhibitorThankYou from "./components/ExhibitorThankyou";
import DkExhibitorThankYou from "./components/dk/dkExhibitorThankyou";
import NoAlreadyRegistered from "./components/no/noAlreadyRegistered";
import NoBanPage from "./components/no/noBanPage";
import NoExhibitorForm from "./components/no/noExhibitorForm";
import NoRegistrationForm from "./components/no/noRegistrationForm";
import NoThankYou from "./components/no/noThankyou";
import NoExhibitorThankYou from "./components/no/noExhibitorThankyou";
import DkmalThankYou from "./components/dkmal/dkmalThankyou.jsx";
import DkmalRegistrationForm from "./components/dkmal/dkmalRegistrationForm.jsx";
import DkmalAlreadyRegistered from "./components/dkmal/dkmalAlreadyRegistered.jsx";
import DkmalBanPage from "./components/dkmal/dkmalBanPage.jsx";
import DkmalExhibitorForm from "./components/dkmal/dkmalExhibitorForm.jsx";
import DkmalExhibitorThankyou from "./components/dkmal/dkmalExhibitorThankyou.jsx";

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <main className="container">
            <Header />
            <Switch>
              <Route exact path="/" component={ChooseExhibition} />
              <Route path="/se/register" component={RegistrationForm} />
              <Route path="/se/exhibitor" component={ExhibitorForm} />
              <Route path="/se/thank-you" component={ThankYou} />
              <Route
                path="/se/already-registered"
                component={AlreadyRegistered}
              />
              <Route path="/se/oops" component={BanPage} />
              <Route path="/se/thank-you-2" component={ExhibitorThankYou} />
              <Route path="/dk/register" component={DkRegistrationForm} />
              <Route path="/dk/exhibitor" component={DkExhibitorForm} />
              <Route path="/dk/thank-you" component={DkThankYou} />
              <Route path="/dk/oops" component={DkBanPage} />
              <Route path="/dk/thank-you-2" component={DkExhibitorThankYou} />
              <Route
                path="/dk/already-registered"
                component={DkAlreadyRegistered}
              />
              <Route path="/dkreg/register" component={DkmalRegistrationForm} />
              <Route path="/dkreg/exhibitor" component={DkmalExhibitorForm} />
              <Route path="/dkreg/thank-you" component={DkmalThankYou} />
              <Route path="/dkreg/oops" component={DkmalBanPage} />
              <Route
                path="/dkreg/thank-you-2"
                component={DkmalExhibitorThankyou}
              />
              <Route
                path="/dkreg/already-registered"
                component={DkmalAlreadyRegistered}
              />
              <Route path="/no/register" component={NoRegistrationForm} />
              <Route path="/no/exhibitor" component={NoExhibitorForm} />
              <Route path="/no/thank-you" component={NoThankYou} />
              <Route path="/no/oops" component={NoBanPage} />
              <Route path="/no/thank-you-2" component={NoExhibitorThankYou} />
              <Route
                path="/no/already-registered"
                component={NoAlreadyRegistered}
              />
              <Route path="/something-went-wrong" component={ErrorPage} />
              <Route path="/notFound" component={NotFound}></Route>
              <Redirect to="/notfound" />
            </Switch>
            <Footer />
          </main>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default App;
