import React from "react";
import { Button, Typography, Container } from "@material-ui/core";
import useStyles from "./styles";

const BanPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.bodyStyle}>
      <Container maxWidth="md">
        <Typography
          className={classes.typographyStyle}
          variant="h5"
          align="center"
          gutterBottom
        >
          Hoppsan, du har registrerat en leverantörsadress. Som utställare ska
          du registrera dig
          {<a href="https://event.agi.se/se/exhibitor"> här</a>}, så att du även
          kan komma in under uppbyggnad och tidigare på morgonen.
        </Typography>
        <Typography
          className={classes.typographyStyle}
          variant="body1"
          align="center"
          gutterBottom
        >
          Är du leverantör och ännu inte bokat plats på mässan, kan du boka
          nyckelfärdiga montrar från 19 900 kronor.
        </Typography>
        <Typography
          className={classes.typographyStyle}
          align="center"
          gutterBottom
        >
          <Button
            href="https://www.signochprint.se/hallplan/"
            variant="contained"
            color="primary"
          >
            Boka en monter här
          </Button>
        </Typography>
      </Container>
    </div>
  );
};

export default BanPage;
