import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  ThemeProvider,
  Typography,
  Container,
  Paper,
} from "@material-ui/core";
import { Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { array, boolean, object, string } from "yup";
import * as yup from "yup";
import { theme } from "../theme";
import useStyles from "../styles";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { SelectFormField } from "../common/selectFormField";
import { TextFormField } from "../common/textFormField";
import BannerCheckbox from "../common/bannerCheckbox";

const exhibitionId = "maldk529374";

const options = [
  {
    label: "A&N Værktøj",
    value: "A&N Værktøj",
  },
  {
    label: "Ahern Denmark",
    value: "Ahern Denmark",
  },
  {
    label: "Alulock ApS",
    value: "Alulock ApS",
  },
  {
    label: "Arbejdsmiljøhuset",
    value: "Arbejdsmiljøhuset",
  },
  {
    label: "Ardex Scandinavia A/S",
    value: "Ardex Scandinavia A/S",
  },
  {
    label: "Arma-Tube A/S",
    value: "Arma-Tube A/S",
  },
  {
    label: "Bach-P A/S og Dalapro",
    value: "Bach-P A/S og Dalapro",
  },
  {
    label: "Beck & Jørgensen A/S",
    value: "Beck & Jørgensen A/S",
  },
  {
    label: "Clemco Danmark A/S",
    value: "Clemco Danmark A/S",
  },
  {
    label: "Climate Coating",
    value: "Climate Coating",
  },
  {
    label: "DVA A/S",
    value: "DVA A/S",
  },
  {
    label: "Dana Lim A/S",
    value: "Dana Lim A/S",
  },
  {
    label: "Danmarks Farve- og Limindustri",
    value: "Danmarks Farve- og Limindustri",
  },
  {
    label: "Decolution",
    value: "Decolution",
  },
  {
    label: "EG Danmark A/S",
    value: "EG Danmark A/S",
  },
  {
    label: "Easy Cover ApS",
    value: "Easy Cover ApS",
  },
  {
    label: "Festool Danmark ApS",
    value: "Festool Danmark ApS",
  },
  {
    label: "Flügger",
    value: "Flügger",
  },
  {
    label: "Flügger DIY & Franchise",
    value: "Flügger DIY & Franchise",
  },
  {
    label: "Fuglsang Algeservice",
    value: "Fuglsang Algeservice",
  },
  {
    label: "GA-Hansen A/S",
    value: "GA-Hansen A/S",
  },
  {
    label: "Guldberg Penselfabrik",
    value: "Guldberg Penselfabrik",
  },
  {
    label: "Hagmans Nordic",
    value: "Hagmans Nordic",
  },
  {
    label: "Hallerup Net",
    value: "Hallerup Net",
  },
  {
    label: "Hammer-Lifte A/S",
    value: "Hammer-Lifte A/S",
  },
  {
    label: "Hultafors Group Danmark A/S",
    value: "Hultafors Group Danmark A/S",
  },
  {
    label: "J.A.K. Workwear",
    value: "J.A.K. Workwear",
  },
  {
    label: "J.M.Hedegaard ApS",
    value: "J.M.Hedegaard ApS",
  },
  {
    label: "James Hardie Denmark",
    value: "James Hardie Denmark",
  },
  {
    label: "Jotun Danmark A/S",
    value: "Jotun Danmark A/S",
  },
  {
    label: "Jysk Lift Service",
    value: "Jysk Lift Service",
  },
  {
    label: "Keim Scandinavia A/S",
    value: "Keim Scandinavia A/S",
  },
  {
    label: "Knauf A/S",
    value: "Knauf A/S",
  },
  {
    label: "Malerfagets faglige Fællesudvalg",
    value: "Malerfagets faglige Fællesudvalg",
  },
  {
    label: "Minuba",
    value: "Minuba",
  },
  {
    label: "Mirka",
    value: "Mirka",
  },
  {
    label: "Nordsjø",
    value: "Nordsjø",
  },
  {
    label: "Novacolor Scandinavia ApS",
    value: "Novacolor Scandinavia ApS",
  },
  {
    label: "Novatech Denmark A/S",
    value: "Novatech Denmark A/S",
  },
  {
    label: "Omme Personlifte",
    value: "Omme Personlifte",
  },
  {
    label: "Orkla House Care Danmark A/S (Spekter)",
    value: "Orkla House Care Danmark A/S (Spekter)",
  },
  {
    label: "PPG Architectural Coatings, Denmark",
    value: "PPG Architectural Coatings, Denmark",
  },
  {
    label: "PPG Industri",
    value: "PPG Industri",
  },
  {
    label: "PPG Progold Plug n Spray",
    value: "PPG Progold Plug n Spray",
  },
  {
    label: "Paint'R ApS",
    value: "Paint'R ApS",
  },
  {
    label: "Pension for selvstændige",
    value: "Pension for selvstændige",
  },
  {
    label: "PensionDanmark",
    value: "PensionDanmark",
  },
  {
    label: "Proløn",
    value: "Proløn",
  },
  {
    label: "Pronelli",
    value: "Pronelli",
  },
  {
    label: "Protox ApS",
    value: "Protox ApS",
  },
  {
    label: "Roico",
    value: "Roico",
  },
  {
    label: "Rubio Monocoat",
    value: "Rubio Monocoat",
  },
  {
    label: "Schuller Eh'klar",
    value: "Schuller Eh'klar",
  },
  {
    label: "Sonnimax",
    value: "Sonnimax",
  },
  {
    label: "Söderberg & Partners",
    value: "Söderberg & Partners",
  },
  {
    label: "Tajima Trading ApS",
    value: "Tajima Trading ApS",
  },
  {
    label: "Vesla-Coating",
    value: "Vesla-Coating",
  },
  {
    label: "World of Comfort",
    value: "World of Comfort",
  },
];

const emptyAttendeeList = {
  exhibitionId: exhibitionId,
  userCategory: "exhibitor",
  company: "",
  registeredAtDate: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

const validationSchema = yup.object({
  company: string()
    .required("Company name is required.")
    .min(2, "Your name needs to be at least 3 characters"),
  termsAndConditions: boolean()
    .required("Terms and conditions must be accepted.")
    .isTrue("Terms and conditions must be accepted."),
  attendees: array(
    object({
      firstName: string()
        .required("First name required.")
        .min(2, "First name needs to be at least 2 characters")
        .max(20, "First name needs to be at most 20 characters"),
      lastName: string()
        .min(2, "Last name must be at least 2 characters")
        .max(20, "Last name needs to be at most 20 characters")
        .required("Last name is required."),
      email: string()
        .email("Enter a valid email")
        .required("E-mail is required."),
    })
  ).min(1, "You need to register at least 1 exhibitor"),
});

function formatDate() {
  return moment().format("YYYY-MM-DD  HH:mm:ss");
}

const DkExhibitorForm = () => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.form}>
        <Container maxWidth="md">
          <Card className={classes.cardBottom}>
            <CardContent>
              <Formik
                initialValues={{
                  company: "",
                  registeredAtDate: "",
                  termsAndConditions: false,
                  attendees: [emptyAttendeeList],
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setSubmitting(true);
                  data.registeredAtDate = formatDate();

                  console.log("Submit: ", data);
                  //http://localhost:9000/exhibitor
                  //https://api.sipp.no/exhibitor
                  //https://api.emjoevents.com/exhibitor
                  axios
                    .post("https://api.emjoevents.com/exhibitor", data)
                    .then((response) => {
                      console.log("Here is the response" + response.data);
                      if (!response.data.exists && response.data.addedToDb) {
                        setSubmitting(false);
                        history.push("/dkreg/thank-you-2");
                      } else if (
                        response.data.exists &&
                        !response.data.addedToDb
                      ) {
                        setSubmitting(false);
                        history.push("/dkreg/already-registered");
                      } else {
                        setSubmitting(false);
                        history.push("/something-went-wrong");
                      }
                    });
                  //SEND POST REQUEST TO API
                  // axios
                  //   .post("http://localhost:9000/testAPI", data)
                  //   .then((response) => {
                  //     console.log(response);
                  //   })
                  //   .catch((error) => {
                  //     console.log(error);
                  //   });

                  //setSubmitting(false);
                  //history.push("/thankyou");
                }}
              >
                {({ values, errors, isSubmitting, isValid }) => (
                  <Form autoComplete="off">
                    <Paper className={classes.topPaper}>
                      <Typography variant="h5" gutterBottom>
                        Exhibitor registration - Danske Malermestres Landsstævne
                        2025
                      </Typography>
                      <Typography variant="body1" gutterBottom></Typography>
                    </Paper>
                    <Paper className={classes.topPaper}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Typography variant="h6" gutterBottom>
                            Choose your company
                          </Typography>
                          <Field
                            options={options}
                            label="Company"
                            name="company"
                            component={SelectFormField}
                          />
                        </Grid>

                        <FieldArray name="attendees">
                          {({ push, remove }) => (
                            <React.Fragment>
                              <Grid item>
                                <Typography variant="h6" gutterBottom>
                                  Attending exhibitors
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  Note that each exhibitor must have a unique
                                  e-mail address.
                                </Typography>
                              </Grid>

                              {values.attendees.map((_, index) => (
                                <Card
                                  className={classes.dynamicCard}
                                  key={index}
                                >
                                  <CardContent>
                                    <Grid
                                      container
                                      item
                                      key={index}
                                      spacing={2}
                                    >
                                      <Grid
                                        item
                                        container
                                        spacing={2}
                                        xs={12}
                                        sm="auto"
                                      >
                                        <Grid item xs={10}>
                                          <Typography>
                                            Exhibitor {index + 1}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                          <Button
                                            className={classes.dynamicButton}
                                            variant="contained"
                                            color="secondary"
                                            disabled={isSubmitting}
                                            onClick={() => remove(index)}
                                          >
                                            Delete
                                          </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Field
                                            fullWidth
                                            name={`attendees.${index}.firstName`}
                                            component={TextFormField}
                                            label="First Name"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Field
                                            fullWidth
                                            name={`attendees[${index}].lastName`}
                                            component={TextFormField}
                                            label="Last Name"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Field
                                            fullWidth
                                            name={`attendees[${index}].email`}
                                            component={TextFormField}
                                            label="E-mail"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Field
                                            fullWidth
                                            name={`attendees[${index}].phone`}
                                            component={TextFormField}
                                            label="Phone"
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              ))}

                              <Grid item>
                                {typeof errors.attendees === "string" ? (
                                  <Typography color="error">
                                    {errors.attendees}
                                  </Typography>
                                ) : null}
                              </Grid>

                              <Grid item>
                                <Button
                                  disabled={isSubmitting}
                                  variant="contained"
                                  onClick={() => push(emptyAttendeeList)}
                                >
                                  Add exhibitor
                                </Button>
                              </Grid>
                            </React.Fragment>
                          )}
                        </FieldArray>

                        <Grid item>
                          <Field
                            name="termsAndConditions"
                            type="checkbox"
                            component={BannerCheckbox}
                            color="primary"
                            label="I accept the terms and conditions"
                            subLabel="The exhibitors above agree that their data can be used to be contacted by the organizer if needed. The data will also be used in anonymized form for marketing purposes.
                "
                          />
                        </Grid>

                        <Grid item>
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size="0.9rem" />
                              ) : undefined
                            }
                          >
                            {isSubmitting ? "Submitting" : "Submit"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>

                    {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default DkExhibitorForm;
