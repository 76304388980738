import React from "react";
import { Button, Typography, Container } from "@material-ui/core";
import useStyles from "../styles";
//import { Helmet } from "react-helmet";

const DkThankYou = () => {
  const classes = useStyles();
  return (
    <div className="body">
      <Container maxWidth="md">
        <div className={classes.bodyStyle}>
          <Typography
            className={classes.typographyStyle}
            variant="h4"
            align="center"
            gutterBottom
          >
            Tak for din tilmelding til messen!
          </Typography>
          <Typography
            className={classes.typographyStyle}
            variant="h6"
            align="center"
            gutterBottom
          >
            Vi sender dig en mail med billetten.
          </Typography>
          <Typography
            className={classes.typographyStyle}
            align="center"
            gutterBottom
          >
            <Button
              href="https://malermestre.dk/kurser-og-arrangementer/danske-malermestres-landsstaevne/"
              variant="contained"
              color="primary"
            >
              Tilbage til messen
            </Button>
          </Typography>
        </div>
      </Container>
      {/* <Helmet>
        <script src="../tracktag.js" type="text/javascript" />
      </Helmet> */}
    </div>
  );
};

export default DkThankYou;
