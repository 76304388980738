import React from "react";
import { Button, Typography, Container } from "@material-ui/core";
import useStyles from "./styles";
//import { Helmet } from "react-helmet";

const ThankYou = () => {
  const classes = useStyles();
  return (
    <div className="body">
      <Container maxWidth="md">
        <div className={classes.bodyStyle}>
          <Typography
            className={classes.typographyStyle}
            variant="h4"
            align="center"
            gutterBottom
          >
            Tack för att du har registrerat dig till mässan!
          </Typography>
          <Typography
            className={classes.typographyStyle}
            variant="h6"
            align="center"
            gutterBottom
          >
            Vi skickar ett mejl med biljetten till dig.
          </Typography>
          <Typography
            className={classes.typographyStyle}
            align="center"
            gutterBottom
          >
            <Button
              href="https://www.signochprint.se/"
              variant="contained"
              color="primary"
            >
              Tillbaka till mässan
            </Button>
          </Typography>
        </div>
      </Container>
      {/* <Helmet>
        <script src="../metatag.js" type="text/javascript" />
      </Helmet> */}
    </div>
  );
};

export default ThankYou;
